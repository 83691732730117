import { mapState, mapGetters } from 'vuex';

export default {
  computed: {
    ...mapState(['hasRadioFeedback', 'campaign', 'hasCouponOnPage', 'selectedElement']),
    ...mapGetters(['isActiveShopifyDomain', 'isEmbedded']),
    actionTypes() {
      const types = [
        { key: 'nextPopup', value: 'nextPopup' },
        { key: 'jumpToPage', value: 'jumpToPage' },
        { key: 'redirect', value: 'redirect' },
        { key: 'dial', value: 'dial' },
      ];

      if (!this.isEmbedded) {
        types.push({ key: 'closePopup', value: 'closePopup' });
      }

      if (this.selectedElement.type === 'OmButton' && this.hasRadioFeedback) {
        types.push({ key: 'basedOnFeedback', value: 'basedOnFeedback' });
      }

      if (this.selectedElement.type === 'OmButton' && this.hasCouponOnPage) {
        types.push({ key: 'copyCoupon', value: 'copyCoupon' });
      }

      // if (this.selectedElement.type === 'OmButton' && this.isActiveShopifyDomain(this.campaign.domain) && this.hasCouponOnPage) {
      //   types.push({ key: 'couponRedeem', value: 'couponRedeem' })
      // }

      return types;
    },
  },
};
